.projectsSection {
    background-color: #f2f2f2;
    padding-bottom: 100px;
    padding-top: 80px;
  }
  
.swiper-card {
    margin-bottom: 20px;
  }

.btn-div {
  display: flex;
  justify-content: center;
}  

.projects-btn {
  background-color: #153828;
  color: white;
  padding: 15px 20px;
  border-radius: 10px;
  margin-top: 50px;
  font-size: 20px;
} 

.projects-btn:hover {
  background-color: gray;
}
  
.projectRow {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 768px) {

  .projectRow {
    flex-direction: column;
    align-items: center;
  }
}

/* Swiper Design */

.swiper-container {
  width: 90%;
  margin: auto;
  padding: 20px 0;
  overflow: hidden;
  z-index: 1;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -10 !important;
  display: flex;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0; 
}

.swiper-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  opacity: 0;
  transform: translateY(30px); 
  transition: opacity 1s ease-out, transform 1s ease-out;
  border-radius: 8px;
}

.swiper-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.swiper-card.visible {
  opacity: 1;
  transform: translateY(0); 
}

.swiper-image {
  width: 100%;
  height: 250px; 
  object-fit: cover;
  border-bottom: 5px solid #153828; 
  border-radius: 8px 8px 0 0;
}

.swiper-card-body {
  padding: 10px 15px 15px 15px;
  background-color: white;
  border-radius: 0 0 8px 8px;
}

.swiper-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 0;
}

.swiper-description {
  font-size: 16px;
  color: #666;
  margin-bottom: 0px;
}

.swiper-button {
  background-color: #153828;
  color: white;
  border: none;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 6px;
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  text-decoration: none;
}

.swiper-button:hover {
  background-color: #5c8f18;
}

