.backgroundSection {
  background-color: lightgrey;
  padding-bottom: 80px;
  width: 100%;
}

.backgroundSection h2 {
  display: flex;
  justify-content: center;
  font-size: 40px;
  padding-bottom: 30px;
}

.backgroundContent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 30%;
}

.iconContainer .icon {
    width: 80%;
}

.textContainer {
  margin-left: 20px;
}

.iconContainer h4 {
  margin: 5px 0;
  font-size: 20px;
  padding: 20px 0 10px 0;
  width: 80%;
  text-align: center;
}

.iconContainer h5 {
  margin: 3px 0;
  font-size: 15px;
  width: 80%;
  text-align: center;
  font-style: italic;
}

.iconContainer p {
  margin: 10px 0;
  font-size: 15px;
}

.wd-27 {
  width: 27%;
}

.docContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.docContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-right: 20px;
}


.docContainer > ul {
  list-style-type: none;
  padding-left: 50px;
}

.docContainer li {
  margin-top: 10px; 
  padding-left: 20px;
}

.docContainer a {
  text-decoration: none;
  color: black; 
}

.docContainer a:hover {
  text-decoration: underline;
  color: rgb(105, 105, 174);
}

@media (max-width: 768px) {
  .backgroundContent {
    flex-direction: column;
    align-items: center;
  }

  .iconContainer {
    width: 80%;
    padding: 20px 0;
  }
}