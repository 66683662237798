.contactMeSection {
    background-color: #153828;
    padding-bottom: 30px;
    color: white;
}

.contactMeSection h2 {
    text-align: center;
    font-size: 40px;   
  }

.contactMeSection p {
    font-size: 18px;
    text-align: center;
    width: 100%;
    padding-bottom: 30px;
}

.social-icons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 4.5%;
}

.contactMeSection .icon {
    color: white;
    padding: 0;
}

@media (max-width: 768px) {

    .contactMeSection .icon {
        color: white;
        padding: 0 10px;
    }
}