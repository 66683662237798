.aboutMe {
  display: flex;
  flex-wrap: wrap;
  background-color: #f8f9fa;
  min-height: 300px;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;
  margin-top: 80px;
  overflow: hidden;
}

.aboutMe h2 {
  display: flex;
  justify-content: center;
  font-size: 40px;
  padding-bottom: 30px;
}

.left-section {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(40% - 20px);
}

.left-section p {
  font-size: 20px;
}

.left-section > h2 {
  margin: 0 0;
}
.left-section > p {
  /* font-weight: 200; */
  font-size: 20px;
}
.right-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: calc(40% - 20px);
}

.profile-image {
  max-width: 70%;
  height: auto;
}

@media (max-width: 768px) {
  .aboutMe {
    flex-direction: column;
    align-items: center;
  }

  .left-section,
  .right-section {
    max-width: 100%; 
    width: 100%; 
    margin: 0;
  }
  
  .right-section {
    padding-left: 0;
    margin-left: 0;
  }
}
