.CompanyImages {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 60%;
  margin: 0 auto;
  padding-bottom: 70px;
}

.company-image {
  width: 100px;
  max-width: 200px;
  height: auto;
  object-fit: contain;
}

@media (max-width: 768px) {
  .company-image {
    max-width: 150px;
  }
}

@media (max-width: 480px) {
  .company-image {
    max-width: 120px;
  }
}
