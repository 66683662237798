.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px; 
  padding: 20px;
  text-align: left;
}

.card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(30px); /* Start with the content slightly below */
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.card.visible {
  opacity: 1;
  transform: translateY(0); /* End at its original position */
}

.card-image {
  width: 100%;
  height: auto;
  /* border-radius: 8px; */
}

.card h3 {
  margin-top: 0;
  font-size: 0.3em;
  color: #0E8E51;
}

.card p {
  font-size: 0.3em;
  color: #555;
}

.card-button {
    padding: 8px 16px;
    background-color: #5c8f18;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .card-button:hover {
    background-color: #ccc;
  }
  