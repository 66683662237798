.Container {
  background-color: lightcyan;
  padding-bottom: 80px;
}

.certificate-title {
  display: flex;
  justify-content: center;
  font-size: 40px;
  padding-bottom: 30px;
}

.certificateRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.certificateContainer {
  /* display: flex;
  flex-wrap: wrap;
  justify-content: center; */
  display: inline-block;
  /* background-color: black; */
  /* color: white; */
  width: 25%;
}

/* .certificateContent {
  display: inline-block; 
  flex-direction: column;
  align-items: center;
  margin: 20px;
} */

.certificateImage {
  width: 100%;
  height: auto;
}

.certificateContainer p {
  font-size: 20px;
  width: 90%;
  text-align: center;
}

@media (max-width: 768px) {
  .certificateRow {
    flex-direction: column;
    align-items: center;
  }

  .certificateContainer {
    width: 70%;
    padding: 20px 0;
  }
}