.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html {
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* nav {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}

nav .logo {
  font-size: 20px;
  display: flex;
  justify-content: start;
  color: black;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: end;
  text-decoration: none;
}

nav ul li {
  display: inline;
  margin-right: 20px;
  
} */

/* nav {
  color: #fff;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d2dcdc;
} */
/* 
nav {
  color: #fff;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
  position: relative;
  z-index: 1000;
} */

.pt-80 {
  padding-top: 80px !important; 
}


/* .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #d2dcdc;;
} */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 15px 20px; */
  z-index: 1000;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.sticky {
  background-color: #d2dcdc; 
  transition: background-color 0.3s ease-in-out;
  /* padding: 15px 20px; */
}

.toggle-button {
  display: none; /* Hide by default, show in mobile */
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.logo {
  font-size: 20px;
  color: black;
  padding-left: 5%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding-right: 5%;
  display: flex;
}

ul li {
  margin-right: 20px;
}

ul li a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

ul li a:hover {
  text-decoration: underline;
  color: gray;
}

/* ul li:last-child {
  margin-right: 0; 
} */

.cover {
  text-align: center;
  /* padding: 180px 0; */
  padding: 260px 0 0 0;
  /* background: linear-gradient(-45deg, #d1e8c3, #afdeba, #b2d3be, #3bb866); */
  background: linear-gradient(-45deg, #c1d6d4, #b9e0b6, #e0d1b2, #a3b79f);
  background-size: 400% 400%;
  /* animation: gradientAnimation 2s ease infinite; */
  animation: gradientWave 6s ease infinite;
  font-size: 40px;
  color: #153828;
  overflow: hidden;
}
/* 
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} */

@keyframes gradientWave {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* Responsive Styles */
@media (max-width: 768px) {

  .toggle-button {
        display: block;
        background: none;
        border: none;
        font-size: 30px;
        cursor: pointer;
        padding-right: 20px;
        position: absolute;
        right: 0;
        top: 15px;
        color: black;
    }

  .nav-links {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: #d2dcdc;
      padding-left: 5%;
  }

  .nav-links.open {
      display: flex;
      padding-bottom: 15px;
  }

  ul {
      flex-direction: column;
      padding: 0;
  }

  ul li {
      margin: 10px 0;
  }

  .cover {
    font-size: 30px;
  }
}

@media (min-width: 769px) {
  nav {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
  }

  .toggle-button {
      display: none;
  }

  .nav-links {
      display: flex !important;
      justify-content: flex-end;
      padding-right: 5%;
  }

  ul {
      flex-direction: row;
  }

  ul li {
      margin-right: 20px;
  }
}