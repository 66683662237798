.DetailsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailsPageCover {
  /* display: flex;
    flex-direction: column;
    align-items: center; */
  text-align: center;
  padding: 100px 0 50px 0;
  background: linear-gradient(-45deg, #c1d6d4, #b9e0b6, #e0d1b2, #a3b79f);
  background-size: 400% 400%;
  animation: gradientWave 6s ease infinite;
  font-size: 40px;
  color: #153828;
  overflow: hidden;
  width: 100%;
}

@keyframes gradientWave {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.detailsPageHeader {
  text-align: left;
  width: 70%;
  margin-left: 70px;
}

.detail-page-image {
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  display: flex;
  justify-content: center;
}

.details-page-title {
  text-align: left;
  font-size: "30px";
}

.design-image {
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.report-image {
  width: 100%;
  max-width: 1000px;
  margin: 0px auto;
  display: flex;
  justify-content: center;
}
